/* eslint-disable no-undef */
// import { LocalStorage } from 'quasar'
// import axios from 'axios'
import Carregando from 'src/components/Carregando/Carregando.vue'
import NotificacaoMixin from 'src/core/mixin/NotificacaoMixin'
import usuarioLoginStore from 'src/store/usuario-login/'

export default {
  name: 'LayoutSite',
  components: { Carregando },
  mixins: [NotificacaoMixin],
  meta () {
    return {
      meta: [
        { 'name': 'robots', 'content': 'noindex' }
      ]
    }
  },
  data () {
    return {
      barraFiltros: true,
      tituloPage: null,
      fotoPerfil: 'avatar.jpg',
      menus: [
        { nome: 'Estoque', url: 'estoque.grid' }
      ],

      carregando: false,
      iconeMenu: 'app:menu',
      forcerRender: 50
    }
  },
  beforeCreate () {
    if (!this.$store.hasModule('usuario-login')) this.$store.registerModule('usuario-login', usuarioLoginStore)
  },
  computed: {
    classeBtnToggle () {
      let classe = 'btn-toggle-lista'
      if (this.barraFiltros) {
        classe += ' ativo '
      }
      return classe
    },
    logado () {
      return this.$store.getters['usuario-login/get']
    },
    vendedorAtivo () {
      const rolesUsuario = this.logado && this.logado.roles ? this.logado.roles.map(role => role.name) : []
      return rolesUsuario.includes('Vendedor')
    }
  },
  mounted () {
    if (this.$q.screen.width < 820) {
      this.barraFiltros = false
    } else {
      this.barraFiltros = true
    }
    this.$store.dispatch('usuario-login/checarLoginEmpresa')
    if (this.logado && this.logado.foto_url_completa) {
      this.fotoPerfil = this.logado.foto_url_completa
      this.forcerRender++
    }
  // this.validarLogado()
  },
  watch: {
    '$q.screen.width':
  {
    handler (agora) {
      if (agora < 820) {
        this.barraFiltros = false
      } else {
        this.barraFiltros = true
      }
    }
  },
    '$route.name' () {
      this.validarLogado()
    }
  },
  methods: {
    validarLogado () {
      if (this.logado) {
      // const temPermissao = this.rotasQueTemPermissao.findIndex(e => e.name === this.$route.name)
      // if (temPermissao < 0) {
      //   this.notificacao('aviso', 'Você não tem permissão de acessar essa página!', 10000)
      //   this.$router.push({ name: 'homesite' })
      // }
      } else {
      // Deslogado
        this.notificacao('aviso', 'Você esta deslogado!', 7000)
        this.irRota({ name: 'login-empresa' })
      }
    },

    irRota (rota) {
      this.$router.push(rota)
    // let routeData = this.$router.resolve(rota)
    // window.location.href = routeData.href
    }
  }
}
